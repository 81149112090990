<template>
  <template v-if="!isLoggedIn">
    <LoginComponent />
  </template>

  <template v-if="isLoggedIn">
    <header
      class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
    >
      <router-link class="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">
        <img src="boekhoudapi-logo-wit.png" alt="BoekhoudAPI" style="height: 25px;" />
      </router-link>
      <button
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </header>

    <div class="container-fluid">
      <div class="row">
        <nav
          id="sidebarMenu"
          class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
        >
          <navHeader />
        </nav>

        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div class="pt-3 pb-2 mb-3">
            <router-view />
          </div>
        </main>
      </div>
    </div>
  </template>
</template>

<script>
import NavHeader from "@/components/NavHeader.vue";
import LoginComponent from "@/components/auth/LoginComponent.vue";
import http from "@/http-common";
import store from "@/store/user";
import router from "@/router";
import loginservice from "@/services/LoginService";

export default {
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = process.env.VUE_APP_TITLE + " | " + to.meta.title;
      },
    },
  },
  components: {
    NavHeader,
    LoginComponent,
  },
  computed: {
    isLoggedIn() {
      /**
       * @todo voor nu omgedraaid zodat je altijd bent ingelogd
       * */
      return store.getters.isLoggedIn;
    },
  },
  methods: {
    checkCredentials() {
      if (window.location.pathname != "/callback") {
        loginservice
          .isLoggedIn()
          .then((response) => {
            if (!response.data) {
              store.dispatch("userLogout");
              if (window.location.pathname != "/") {
                router.push("/");
              }
            }
          })
          .catch((error) => {
            store.dispatch("userLogout");
            if (window.location.pathname != "/") {
              router.push("/");
            }
          });
      }
    },
  },
  mounted() {
    http.defaults["headers"]["Authorization"] = store.getters.getAuthHeader;
    this.checkCredentials();
  },
};

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch("userLogout");
      router.push("/");
    }
    return Promise.reject(error);
  }
);
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
.p-datatable-table {
  table-layout: fixed !important;
  width: 100%;
  margin: 0px 0px 2rem 0px;
  padding: 0px !important;
  line-height: 2.2rem;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper {
  overflow: unset !important;
}

.p-datatable-table>:not(caption)>*>* {
  padding: .5rem .5rem;
  box-shadow: none;
}

.p-datatable-table td:not([colspan]) {
  border-top: 1px solid #DBE1E5;
}

.p-datatable-table tr:last-child td {
  border-bottom: 1px solid #DBE1E5;
}

.p-datatable-table th
{
  font-size: 13.5px;
  font-weight: normal;
  color: #78909C; /* blue grey 300 */
  line-height: 1.4rem;
  border-bottom: 1px solid #DBE1E5;
}

.p-datatable-table>:not(:first-child) {
  border-top: none;
}

.p-datatable-table .badge {
  font-weight: normal;
}
</style>

<style scoped>
@media (min-width: 768px) {
  .d-md-block {
    display: flex !important;
  }
}
</style>
